

.schedule-page{
  position: relative;
  .kurt-logo{
    width: 62px;
    height: auto;

    .color{
      fill: #7b7b7b;
      stroke: #7b7b7b;
    }
  }


}