.spinner-overlay {
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0px auto;
  background-color: white;
  opacity: 0.5;

  .spinner-border{
    margin-left: 50%;
    margin-top: 50%;
  }
}
