
.calendar-parent {

  .react-datepicker-wrapper, .react-datepicker__input-container {
    input {
      border: none;
      border-bottom: solid 2px #7b7b7b;
      text-align: right;
      font-size: larger;
      width: 120px;
    }
  }


  &> div {
    display: inline-block;
  }

}
