@import '~bootstrap/scss/bootstrap.scss';

.time-picker.table {

  td {
    max-width: 0;
    padding: 0.45rem 0.75rem;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      background-color: lightgray;
      cursor: pointer;
    }
    &.expired{
      background-color: #C38D9E;
      opacity: 0.8;
      &:hover {
        cursor: auto;
      }
    }

    &.full {
      //background-color: #41B3A3;
      background-color: darkgray;

      &:hover {
        cursor: auto;
      }

      &.border-bottom-none {
        border-bottom-color: darkgray;
      }

      &.border-top-none {
        border-top-color: darkgray;
      }
    }

    &.full-training {
      //background-color: #41B3A3;
      background-color: #fdce4b;

      &:hover {
        cursor: auto;
      }

      &.border-bottom-none {
        border-bottom-color: #fdce4b;
      }

      &.border-top-none {
        border-top-color: #fdce4b;
      }
    }



    &.selected {
      background-color: #85cdca;
    }

    .flex-parent {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }

    .short-and-fixed {
      white-space: nowrap;

      > div {
        width: 30px;
        border-radius: 10px;
        background: lightgreen;
        display: inline-block;
      }
    }

    .long-and-truncated {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
