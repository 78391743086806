svg.light{
  opacity: 0.6;
}

svg.light:hover{
  opacity: 1;
}




svg.clickable:hover{
  cursor: pointer;
}