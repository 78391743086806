.tile {
  -webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.6s; /* Firefox < 16 */
  -ms-animation: fadein 0.6s; /* Internet Explorer */
  -o-animation: fadein 0.6s; /* Opera < 12.1 */
  animation: fadein 0.6s;
}

@keyframes fadein {
  from { opacity: 0.2; }
  to   { opacity: 1; }
}