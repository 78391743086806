.disabled-overlay {
  z-index: 21;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0px auto;
  background-color: white;
  opacity: 0.5;
}
